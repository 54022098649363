import React, { Component, forwardRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import esCR from 'date-fns/locale/es';
registerLocale('es', esCR);

class DatePickerControl extends Component {

    getDateValue = value => {
        if (value instanceof Date) {
            if (value.getFullYear() >= 1900) {
                return value;
            }
            else {
                return new Date();
            }
        }
        
        const nDate = moment(value, 'YYYY-MM-DD');
        if(nDate.isValid()) {
            return new Date(value);
        }
        else {
            return new Date();
        }
    }

    render() {

        const ref = React.createRef();

        const CustomInput = forwardRef(({ value, onClick }, ref) => (
            <div className="input-group date">
                <input type="text" className="form-control" defaultValue={value} onClick={onClick} ref={ref} />
                <div className="input-group-append">
                    <span className="input-group-text" onClick={onClick}><FontAwesomeIcon icon={faCalendarAlt} /></span>
                </div>
            </div>));

        return <div>
            <DatePicker dateFormat="dd/MM/yyyy"
                className="max-width"
                locale="es"
                {...this.props.props}
                value={this.getDateValue(this.props.value)}
                minDate={new Date(1900, 0, 1)}
                dropdownMode="select"
                showYearDropdown
                showMonthDropdown
                scrollableYearDropdown
                selected={this.getDateValue(this.props.value)}
                onChange={date => this.props.onChange(this.props.name, date)}
                customInput={<CustomInput ref={ref} />} />
        </div>;
    }
}

export default DatePickerControl;