import { RepuestoRutas, GetResponseBody, GetHeaderRequest } from './PathConfig';

export const Consultar_Repuesto_Por_Id_Request = async (pToken, pConsulta) => {
    const result = await fetch(RepuestoRutas.Obtener_Stock_Repuesto_Compania_Por_Id, {
        method: 'POST',
        headers: GetHeaderRequest(pToken),
        body: JSON.stringify(pConsulta)
    });
    return await GetResponseBody(result);
};
