import React, { Component } from 'react';
import { Route, Switch } from 'react-router';

import { SiteRutas } from './request/PathConfig';
import Layout from './containers/Layout';

import CompaniaPage from './containers/Compania/CompaniaPage';
import CompaniaEditorPage from './containers/Compania/CompaniaEditorPage';
import EmpresaPage from './containers/Empresa/EmpresaPage';
import EmpresaEditorPage from './containers/Empresa/EmpresaEditorPage';
import LoginPage from './containers/Login/LoginPage';
import HomePage from './containers/Repuesto/RepuestoPage';

export default class App extends Component {
  render() {
    return (
      <Switch>
        <Route path={[SiteRutas.Home, SiteRutas.CompaniaEditor, SiteRutas.Companias, SiteRutas.EmpresaEditor, SiteRutas.Empresas]}>
          <Layout>
            <Switch>
              <Route path={SiteRutas.Home} component={HomePage} />
              <Route path={SiteRutas.Companias} component={CompaniaPage} />
              <Route path={SiteRutas.Empresas} component={EmpresaPage} />
              <Route path={SiteRutas.CompaniaEditor} component={CompaniaEditorPage} />
              <Route path={SiteRutas.EmpresaEditor} component={EmpresaEditorPage} />
              <Route component={HomePage} />
            </Switch>
          </Layout>
        </Route>
        <Route path={[SiteRutas.Login, SiteRutas.Base]}>
          <Switch>
            <Route path={SiteRutas.Login} component={LoginPage} />
            <Route component={LoginPage} />
          </Switch>
        </Route>
      </Switch>
    );
  }
}
