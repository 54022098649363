import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { SiteRutas } from '../../../request/PathConfig';
import Checkbox from '../../../components/UI/Checkbox/Checkbox';

import { Notification } from '../../../components/Util/Notification/Notification';
import { Asociar_Compania_Empresa_Request, Desasociar_Compania_Empresa_Request, Obtener_Companias_Por_Empresa_Request, Obtener_Companias_Request } from '../../../request/CompaniaRequest';

class CompaniasSeccion extends PureComponent {
    _isMounted = false;
    state = {
        lstCompanias: []
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.estaLogueado && this.props.usuario) {
            if (this.props.empresa_Id) {
                this.cargarCompanias();
            }
        }
        else {
            let { history } = this.props;
            history.push({
                pathname: SiteRutas.Login
            });
        }
    }

    async cargarCompanias() {
        const response = await Obtener_Companias_Request(this.props.token);
        if (response.Exitoso) {
            let lstCompanias = [];
            if (response.Datos && response.Datos.length > 0) {
                lstCompanias = response.Datos.filter(compania => compania.Compania_Activa);
            }
            this._isMounted && this.setState({ lstCompanias }, () => {
                if (lstCompanias.length > 0) {
                    this.cargarCompaniasXEmpresa();
                }
            });
        }
        else {
            Notification.error(response.Mensaje);
            if (response.CodigoError === 2) {
                let { history } = this.props;
                history.push({
                    pathname: SiteRutas.Login
                });
            }
        }
    }

    async cargarCompaniasXEmpresa() {
        const response = await Obtener_Companias_Por_Empresa_Request(this.props.token, this.props.empresa_Id);
        if (response.Exitoso) {
            if (this._isMounted && response.Datos && response.Datos.length > 0) {
                let lstCompanias = [...this.state.lstCompanias];
                const lstCompaniasXEmpresa = response.Datos.map(compania => compania.Compania_Id);
                lstCompanias = lstCompanias.map(compania => {
                    const checked = lstCompaniasXEmpresa.includes(compania.Compania_Id);
                    compania.checked = checked;
                    return compania;
                });

                this.setState({ lstCompanias });
            }
        }
        else {
            Notification.error(response.Mensaje);
            if (response.CodigoError === 2) {
                let { history } = this.props;
                history.push({
                    pathname: SiteRutas.Login
                });
            }
        }
    }

    async asociarCompania(pCompania_Id, pEmpresa_Id) {
        const response = await Asociar_Compania_Empresa_Request(this.props.token, pCompania_Id, pEmpresa_Id);
        if (response.Exitoso) {
            Notification.success(response.Mensaje);
        }
        else {
            Notification.error(response.Mensaje);
            if (response.CodigoError === 2) {
                let { history } = this.props;
                history.push({
                    pathname: SiteRutas.Login
                });
            }
        }
    }

    async desasociarCompania(pCompania_Id, pEmpresa_Id) {
        const response = await Desasociar_Compania_Empresa_Request(this.props.token, pCompania_Id, pEmpresa_Id);
        if (response.Exitoso) {
            Notification.success(response.Mensaje);
        }
        else {
            Notification.error(response.Mensaje);
            if (response.CodigoError === 2) {
                let { history } = this.props;
                history.push({
                    pathname: SiteRutas.Login
                });
            }
        }
    }

    onChangeCheckBox = (event, pCompania_Id) => {
        const { checked } = event.currentTarget;

        if (this.props.empresa_Id) {
            if (checked) {
                this.asociarCompania(pCompania_Id, this.props.empresa_Id);
            }
            else {
                this.desasociarCompania(pCompania_Id, this.props.empresa_Id);
            }
        }
        else {
            Notification.error("No se encontro una empresa para realizar la operación.");
        }

        let lstCompanias = [...this.state.lstCompanias];
        const index = lstCompanias.findIndex(compania => compania.Compania_Id === pCompania_Id);

        if (index >= 0) {
            let item = { ...lstCompanias[index] };
            item.checked = checked;
            lstCompanias[index] = item;
            this.setState({ lstCompanias })
        }
    }

    onClickGoBack = event => {
        let { history } = this.props;
        history.push({
            pathname: SiteRutas.Empresas
        });
    }

    render() {
        const { lstCompanias } = this.state;

        const checkboxs = lstCompanias.map((compania, index) => {
            return <div key={compania.Compania_Id} className="row">
                <div className={`col ${index === 0 ? '' : 'mt-4'}`}>
                    <Checkbox id={`chkCompania${compania.Compania_Id}`}
                        labelText={compania.Compania_Nombre}
                        onChange={(event) => this.onChangeCheckBox(event, compania.Compania_Id)}
                        checked={compania.checked} />
                </div>
            </div>;
        });

        return <Fragment>
            <div className="row">
                <div className="col-6">
                    <h6>Seleccione las compañías asociadas a la empresa</h6>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {checkboxs}
                </div>
            </div>
            <div className="row mb-3 mt-4">
                <div className="col text-right">
                    <button className="btn btn-secondary mr-1" onClick={this.onClickGoBack}>Cancelar</button>
                </div>
            </div>
        </Fragment>;
    }
}

const mapStateToProps = state => {
    return {
        token: state.usuario.token,
        usuario: state.usuario.usuario,
        estaLogueado: state.usuario.estaLogueado
    };
};

export default connect(mapStateToProps)(withRouter(CompaniasSeccion));