export const toCurrency = (pAmount) => {
    return pAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const checkNumber = (value, minLength, maxLength) => {
    const pattern = new RegExp(`^[0-9]{${minLength},${maxLength}}$`);
    return pattern.test(value);
};

export const checkEmail = (value) => {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return pattern.test(value);
};

export const checkPhone = (value) => {
    const pattern = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[-. ]?)?((?:\(?\d+\)?[-. ]?)*)(?:[-. ]?(?:#|ext\.?|extension|x)[-. ]?(\d+))?$/;
    return pattern.test(value);
};

export const checkPasswordComplexity = (pPassword) => {

    if (pPassword.length < 8 || pPassword.length > 25) {
        return "La Contraseña debe tener entre 8 y 30 caracteres.";
    }
    let uppercase = false;
    let lowercase = false;
    let number = false;
    let specialCharacter = false;

    let index = 0;
    while (index < pPassword.length && (!uppercase || !lowercase || !number || !specialCharacter)) {
        if (pPassword.charCodeAt(index) >= 65 && pPassword.charCodeAt(index) <= 90) {
            uppercase = true;
        }
        else if (pPassword.charCodeAt(index) >= 97 && pPassword.charCodeAt(index) <= 122) {
            lowercase = true;
        }
        else if (pPassword.charCodeAt(index) >= 48 && pPassword.charCodeAt(index) <= 57) {
            number = true;
        }
        else {
            specialCharacter = true;
        }
        index++;
    }

    if (!uppercase) {
        return "La Contraseña debe contener al menos una letra mayúscula.";
    }

    if (!lowercase) {
        return "La Contraseña debe contener al menos una letra minúscula.";
    }

    if (!number) {
        return "La Contraseña debe contener al menos un número.";
    }

    if (!specialCharacter) {
        return "La contraseña debe contener al menos un carácter especial.";
    }

    return '';
};