import React, { Fragment } from 'react';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import '../assets/css/style.css';

export default props => {
    return (
        <Fragment>
            <Header />
            <div className="content">
                {props.children}
            </div>
            <Footer />
        </Fragment>
    );
};
