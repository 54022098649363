import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faTrashAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { SiteRutas } from '../../request/PathConfig';
import Mensaje from '../../components/Util/Notification/Mensaje';
import { Notification } from '../../components/Util/Notification/Notification';
import { Obtener_Empresas_Request, Eliminar_Empresa_Request } from '../../request/EmpresaRequest';

const empresaImagen = require('../../assets/images/empresa.png');

class EmpresaPage extends PureComponent {
    _isMounted = false;
    state = {
        selectedEmpresa: null,
        registros: [],
        loading: false
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.estaLogueado && this.props.usuario && this.props.usuario.Rol_Id === 1) {
            this.cargarRegistros();
        }
        else {
            let { history } = this.props;
            history.push({
                pathname: SiteRutas.Login
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async cargarRegistros() {
        this.setState({ loading: true });

        const response = await Obtener_Empresas_Request(this.props.token);
        if (response.Exitoso) {
            this._isMounted && this.setState({ loading: false, registros: response.Datos || [] });
        }
        else {
            this._isMounted && this.setState({ loading: false });
            Notification.error(response.Mensaje);
            if (response.CodigoError === 2) {
                let { history } = this.props;
                history.push({
                    pathname: SiteRutas.Login
                });
            }
        }
    }

    async eliminarRegistro(pRegistro) {
        const resultado = await Mensaje.confirmacion('Confirmación', `¿Está seguro que desea eliminar la empresa con código ${pRegistro.Empresa_Id}?`, 'Eliminar');
        if (resultado) {
            const response = await Eliminar_Empresa_Request(this.props.token, pRegistro.Empresa_Id);
            if (response.Exitoso) {
                const indiceRegistro = this.state.registros.findIndex(item => item.Empresa_Id === pRegistro.Empresa_Id);
                if (indiceRegistro > -1) {
                    let registros = [...this.state.registros];
                    registros.splice(indiceRegistro, 1);
                    this._isMounted && this.setState({ registros });
                }

                Notification.success(response.Mensaje);
            }
            else {
                if (response.Mensaje.includes("FK_")) {
                    Notification.error("Ocurrió un error al intentar eliminar la empresa, por favor verifique sus dependencias.");
                }
                else {
                    Notification.error(response.Mensaje);
                    if (response.CodigoError === 2) {
                        let { history } = this.props;
                        history.push({
                            pathname: SiteRutas.Login
                        });
                    }
                }
            }
        }
    }

    onClickEliminar = event => {
        const { selectedEmpresa } = this.state;
        if (selectedEmpresa) {
            this.eliminarRegistro(selectedEmpresa);
        }
        else {
            Notification.warning('Por favor, seleccione la empresa que desea eliminar.');
        }
    }

    onClickNewEdit = pEmpresa => {
        let path = {
            pathname: SiteRutas.EmpresaEditor,
            state: {}
        };
        if (pEmpresa) {
            path.state.empresa = pEmpresa;
        }

        this.props.history.push(path);
    }

    onClickEditar = () => {
        const { selectedEmpresa } = this.state;
        if (selectedEmpresa) {
            this.onClickNewEdit(selectedEmpresa);
        }
        else {
            Notification.warning('Por favor, seleccione la empresa que desea modificar.');
        }
    }

    onSelectedRowEmpresa = (row, isSelect, rowIndex, e) => {
        this.setState({ selectedEmpresa: isSelect ? row : null });
    }

    render() {
        const paginacionOpciones = {
            sizePerPage: 7,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true
        };

        const { SearchBar } = Search;

        const columns = [{
            dataField: 'Empresa_Id',
            text: 'Cédula'
        }, {
            dataField: 'Empresa_Nombre',
            text: 'Nombre'
        }, {
            dataField: 'Empresa_Activa',
            text: 'Estado',
            formatter: (cellContent, row) => (
                row.Empresa_Activa ? 'Activa' : 'Inactiva'
            )
        }, {
            dataField: 'Empresa_Telefono',
            text: 'Teléfono'
        }, {
            dataField: 'Empresa_Email',
            text: 'Correo Electrónico'
        }];

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn: true,
            nonSelectable: [],
            bgColor: 'rgba(0,0,0,.075)',
            onSelect: this.onSelectedRowEmpresa
        };

        const rowEvents = {
            onDoubleClick: (e, row, rowIndex) => {
                this.onClickNewEdit(row);
            }
        };

        return (
            <ToolkitProvider
                keyField='Empresa_Id'
                data={this.state.registros}
                columns={columns}
                search={{ searchFormatted: true }}>
                {
                    props => (
                        <Fragment>
                            <div className="main-titles">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <h2>Administración de Empresas</h2>
                                        </div>
                                        <div className="col text-right">
                                            <img className="img-fluid" src={empresaImagen} alt='imagen empresa' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="botones">
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <SearchBar placeholder='Buscar' {...props.searchProps} />
                                        </div>
                                        <div className="col text-right">
                                            <button className="btn btn-success mr-1" title="Nuevo" onClick={() => this.onClickNewEdit(null)}><FontAwesomeIcon icon={faPlus} /></button>
                                            <button className="btn btn-success mr-1" title="Editar" onClick={this.onClickEditar}><FontAwesomeIcon icon={faPen} /></button>
                                            <button className="btn btn-success" title="Eliminar" onClick={this.onClickEliminar}><FontAwesomeIcon icon={faTrashAlt} /></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mt-4">
                                        <div className="table-responsive">
                                            <BootstrapTable
                                                {...props.baseProps}
                                                selectRow={selectRow}
                                                rowEvents={rowEvents}
                                                pagination={paginationFactory(paginacionOpciones)}
                                                noDataIndication="No se encontraron registros."
                                                bootstrap4
                                                hover
                                            />
                                        </div>
                                        {
                                            this.state.loading ?
                                                <React.Fragment>
                                                    Cargando Información... <FontAwesomeIcon icon={faSpinner} spin />
                                                </React.Fragment>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )
                }
            </ToolkitProvider >
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usuario.token,
        usuario: state.usuario.usuario,
        estaLogueado: state.usuario.estaLogueado
    };
};

export default connect(mapStateToProps)(withRouter(EmpresaPage));