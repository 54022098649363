import { EmpresaRutas, GetResponseBody, GetHeaderRequest } from './PathConfig';

export const Actualizar_Empresa_Request = async (pToken, pEmpresa) => {
    const result = await fetch(EmpresaRutas.Actualizar_Empresa, {
        method: 'POST',
        headers: GetHeaderRequest(pToken),
        body: JSON.stringify(pEmpresa)
    });
    return await GetResponseBody(result);
};

export const Eliminar_Empresa_Request = async (pToken, pEmpresa_Id) => {
    const result = await fetch(`${EmpresaRutas.Eliminar_Empresa}?pEmpresa_Id=${pEmpresa_Id}`, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};

export const Insertar_Empresa_Request = async (pToken, pEmpresa) => {
    const result = await fetch(EmpresaRutas.Insertar_Empresa, {
        method: 'POST',
        headers: GetHeaderRequest(pToken),
        body: JSON.stringify(pEmpresa)
    });
    return await GetResponseBody(result);
};

export const Obtener_Empresas_Request = async (pToken) => {
    const result = await fetch(EmpresaRutas.Obtener_Empresas, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};

export const Obtener_Empresa_Por_Id_Request = async (pToken, pEmpresa_Id) => {
    const result = await fetch(`${EmpresaRutas.Obtener_Empresa_Por_Id}?pEmpresa_Id=${pEmpresa_Id}`, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};