import { CompaniaRutas, GetResponseBody, GetHeaderRequest, GetHeaderRequestFormData } from './PathConfig';

export const Actualizar_Compania_Request = async (pToken, pCompania, pFormFile) => {
    const propiedades = Object.keys(pCompania);

    let form = new FormData();
    propiedades.forEach(propiedad => {
        form.append(propiedad, pCompania[propiedad]);
    });
    form.append('pFormFile', pFormFile);

    const result = await fetch(CompaniaRutas.Actualizar_Compania, {
        method: 'POST',
        headers: GetHeaderRequestFormData(pToken),
        body: form
    });
    return await GetResponseBody(result);
};

export const Asociar_Compania_Empresa_Request = async (pToken, pCompania_Id, pEmpresa_Id) => {
    const data = { Compania_Id: pCompania_Id, Empresa_Id: pEmpresa_Id };
    const result = await fetch(CompaniaRutas.Asociar_Compania_Empresa, {
        method: 'POST',
        headers: GetHeaderRequest(pToken),
        body: JSON.stringify(data)
    });
    return await GetResponseBody(result);
};

export const Desasociar_Compania_Empresa_Request = async (pToken, pCompania_Id, pEmpresa_Id) => {
    const data = { Compania_Id: pCompania_Id, Empresa_Id: pEmpresa_Id };
    const result = await fetch(CompaniaRutas.Desasociar_Compania_Empresa, {
        method: 'POST',
        headers: GetHeaderRequest(pToken),
        body: JSON.stringify(data)
    });
    return await GetResponseBody(result);
};

export const Eliminar_Compania_Request = async (pToken, pCompania_Id) => {
    const result = await fetch(`${CompaniaRutas.Eliminar_Compania}?pCompania_Id=${pCompania_Id}`, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};

export const Insertar_Compania_Request = async (pToken, pCompania, pFormFile) => {
    const propiedades = Object.keys(pCompania);

    let form = new FormData();
    propiedades.forEach(propiedad => {
        form.append(propiedad, pCompania[propiedad]);
    });
    form.append('pFormFile', pFormFile);

    const result = await fetch(CompaniaRutas.Insertar_Compania, {
        method: 'POST',
        headers: GetHeaderRequestFormData(pToken),
        body: form
    });
    return await GetResponseBody(result);
};

export const Obtener_Companias_Request = async (pToken) => {
    const result = await fetch(CompaniaRutas.Obtener_Companias, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};

export const Obtener_Companias_Por_Empresa_Request = async (pToken, pEmpresa_Id) => {
    const result = await fetch(`${CompaniaRutas.Obtener_Companias_Por_Empresa}?pEmpresa_Id=${pEmpresa_Id}`, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};

export const Obtener_Compania_Por_Id_Request = async (pToken, pEmpresa_Id) => {
    const result = await fetch(`${CompaniaRutas.Obtener_Compania_Por_Id}?pEmpresa_Id=${pEmpresa_Id}`, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};