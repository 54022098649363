import React, { PureComponent, Fragment, memo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import { SiteRutas } from '../../../request/PathConfig';
import { checkEmail, checkPasswordComplexity, checkNumber } from '../../../components/Util/Util';
import { Notification } from '../../../components/Util/Notification/Notification';
import { Insertar_Usuario_Request, Actualizar_Usuario_Request } from '../../../request/UsuarioRequest';
import { Obtener_Empresas_Request } from '../../../request/EmpresaRequest';
import DatePicker from '../../../components/UI/DatePicker/DatePicker';

class UsuarioEditorSeccion extends PureComponent {
    _isMounted = false;
    state = {
        esNuevo: true,
        usuario: {
            Empresa_Id: this.props.empresa_Id,
            Usuario_Id: '',
            Usuario_Cedula: '',
            Rol_Id: 2,
            Usuario_Nombre: '',
            Usuario_Apellido1: '',
            Usuario_Apellido2: '',
            Usuario_Password: '',
            Usuario_Password_Confirmacion: '',
            Usuario_Email: '',
            Usuario_Fec_Vencimiento: new Date(new Date().setMonth(new Date().getMonth() + 1)),
            Usuario_Activo: true,
            Usuario_Fec_Actualizacion: new Date(),
            Usuario_Id_Actualiza: this.props.usuario_actual.Usuario_Id
        },
        lstEmpresas: [],
        btnDisabled: false,
        loading: false
    }

    componentDidMount() {
        this._isMounted = true;
        this.cargarEmpresas();
        if (this.props.usuario) {
            this._isMounted && this.setState({ usuario: this.props.usuario, esNuevo: false });
        }
        else {
            this._isMounted && this.setState({
                usuario: {
                    Empresa_Id: this.props.empresa_Id,
                    Usuario_Id: '',
                    Usuario_Cedula: '',
                    Rol_Id: 2,
                    Usuario_Nombre: '',
                    Usuario_Apellido1: '',
                    Usuario_Apellido2: '',
                    Usuario_Password: '',
                    Usuario_Password_Confirmacion: '',
                    Usuario_Email: '',
                    Usuario_Fec_Vencimiento: new Date(new Date().setMonth(new Date().getMonth() + 1)),
                    Usuario_Activo: true,
                    Usuario_Fec_Actualizacion: new Date(),
                    Usuario_Id_Actualiza: this.props.usuario_actual.Usuario_Id
                }, esNuevo: true
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async cargarEmpresas() {
        const response = await Obtener_Empresas_Request(this.props.token);
        if (response.Exitoso) {
            this._isMounted && this.setState({ lstEmpresas: response.Datos || [] });
        }
        else {
            Notification.error(response.Mensaje);
            if (response.CodigoError === 2) {
                let { history } = this.props;
                history.push({
                    pathname: SiteRutas.Login
                });
            }
        }
    }

    async guardarUsuario() {
        const usuario = { ...this.state.usuario };
        if (this.validarDatosUsuario(usuario)) {
            let response = null;
            if (this.state.esNuevo) {
                response = await Insertar_Usuario_Request(this.props.token, usuario);
            }
            else {
                response = await Actualizar_Usuario_Request(this.props.token, usuario);
            }

            if (response.Exitoso) {
                this._isMounted && this.state.esNuevo && this.setState({ esNuevo: false });
                Notification.success(response.Mensaje);
            }
            else {
                Notification.error(response.Mensaje);
                if (response.CodigoError === 2) {
                    let { history } = this.props;
                    history.push({
                        pathname: SiteRutas.Login
                    });
                }
            }
        }
        this._isMounted && this.setState({ btnDisabled: false, loading: false });
    }

    validarDatosUsuario = pUsuario => {

        const index = this.state.lstEmpresas.findIndex(empresa => empresa.Empresa_Id === pUsuario.Empresa_Id);
        if (index < 0 || !pUsuario.Empresa_Id) {
            Notification.error("Debe seleccionar una empresa para poder continuar.");
            return false;
        }

        if (!pUsuario.Usuario_Nombre) {
            Notification.error("El Nombre del usuario es requerido.");
            return false;
        }

        if (pUsuario.Usuario_Nombre.length > 25) {
            Notification.error("El Nombre del usuario debe contener como máximo 25 caracteres.");
            return false;
        }

        if (!pUsuario.Usuario_Apellido1) {
            Notification.error("El Primer Apellido del usuario es requerido.");
            return false;
        }

        if (pUsuario.Usuario_Apellido1.length > 25) {
            Notification.error("El Primer Apellido del usuario debe contener como máximo 25 caracteres.");
            return false;
        }

        if (!pUsuario.Usuario_Apellido2) {
            Notification.error("El Segundo Apellido del usuario es requerido.");
            return false;
        }

        if (pUsuario.Usuario_Apellido2.length > 25) {
            Notification.error("El Segundo Apellido del usuario debe contener como máximo 25 caracteres.");
            return false;
        }

        if (!pUsuario.Usuario_Cedula) {
            Notification.error("La Cédula del usuario es requerida.");
            return false;
        }

        if (pUsuario.Usuario_Cedula.length > 30) {
            Notification.error("La Cédula del usuario debe contener como máximo 30 dígitos.");
            return false;
        }

        if (!pUsuario.Usuario_Id) {
            Notification.error("El Login del usuario es requerido.");
            return false;
        }

        if (pUsuario.Usuario_Id.length > 30) {
            Notification.error("El Login del usuario debe contener como máximo 30 caracteres.");
            return false;
        }

        if (this.state.esNuevo || pUsuario.Usuario_Password) {
            if (!pUsuario.Usuario_Password) {
                Notification.error("La Contraseña del usuario es requerida.");
                return false;
            }

            const mensaje = checkPasswordComplexity(pUsuario.Usuario_Password);
            if (mensaje) {
                Notification.error(mensaje);
                return false;
            }

            if (!pUsuario.Usuario_Password_Confirmacion) {
                Notification.error("La Confirmación de la Contraseña es requerida.");
                return false;
            }

            if (pUsuario.Usuario_Password !== pUsuario.Usuario_Password_Confirmacion) {
                Notification.error("La Contraseña no coincide con la Confirmación de la Contraseña.");
                return false;
            }
        }

        if (!pUsuario.Usuario_Email || !checkEmail(pUsuario.Usuario_Email)) {
            Notification.error("Debe ingresar un Correo Electrónico válido.");
            return false;
        }

        if (pUsuario.Usuario_Email.length > 100) {
            Notification.error("El Correo Electrónico debe contener como máximo 100 caracteres.");
            return false;
        }

        const date = moment(pUsuario.Usuario_Fec_Vencimiento);
        if (!date || !date.isValid() || new Date(pUsuario.Usuario_Fec_Vencimiento).getFullYear() < 1900) {
            Notification.error("Debe ingresar una Fecha de Vencimiento válida mayor o igual a 01/01/1900.");
            return false;
        }

        return true;
    }

    onClickGuardarUsuario = () => {
        this.setState({ btnDisabled: true, loading: true }, () => {
            this.guardarUsuario();
        });
    }

    onChangeInput = event => {
        let { name, value } = event.currentTarget;
        this.setState({ usuario: { ...this.state.usuario, [name]: value } });
    }

    onChangeCheckBox = event => {
        const { name, checked } = event.currentTarget;
        this.setState({ usuario: { ...this.state.usuario, [name]: checked } });
    }

    onChangeDate = (name, date) => {
        let usuario = { ...this.state.usuario };
        usuario[name] = date;

        this.setState({ usuario });
    }

    onChangeCedula = event => {
        const { name, value } = event.currentTarget;
        if (!value || checkNumber(value, 1, 30))
            this.setState({ usuario: { ...this.state.usuario, [name]: value } });
    }


    render() {
        const { usuario, esNuevo, lstEmpresas, loading, btnDisabled } = this.state;

        return <Fragment>
            <div className="row">
                <div className="col-6 mb-4">
                    <h6>{`${esNuevo ? ' Nuevo' : ' Editar'} Usuario`}</h6>
                </div>
            </div>
            <div className="row">
                <div className="col col-sm-6">
                    <div className="form-group">
                        <label htmlFor="ddlEmpresa">Empresa</label>
                        <select id="ddlEmpresa" className="form-control" name="Empresa_Id" onChange={this.onChangeInput} value={usuario.Empresa_Id}>
                            {
                                lstEmpresas && lstEmpresas.length > 0 ?
                                    lstEmpresas.map(empresa => <option key={empresa.Empresa_Id} value={empresa.Empresa_Id}>{empresa.Empresa_Nombre}</option>)
                                    : null
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="txtUsuarioNombre">Nombre de Usuario</label>
                        <input id="txtUsuarioNombre" className="form-control"
                            type="text"
                            name="Usuario_Nombre"
                            maxLength={25}
                            value={usuario.Usuario_Nombre}
                            onChange={this.onChangeInput}
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="txtUsuarioApellido1">Primer Apellido</label>
                        <input id="txtUsuarioApellido1" className="form-control"
                            type="text"
                            name="Usuario_Apellido1"
                            maxLength={25}
                            value={usuario.Usuario_Apellido1}
                            onChange={this.onChangeInput}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="txtUsuarioApellido2">Segundo Apellido</label>
                        <input id="txtUsuarioApellido2" className="form-control"
                            type="text"
                            name="Usuario_Apellido2"
                            maxLength={25}
                            value={usuario.Usuario_Apellido2}
                            onChange={this.onChangeInput}
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="txtUsuarioCedula">Cédula</label>
                        <input id="txtUsuarioCedula" className="form-control"
                            type="number"
                            name="Usuario_Cedula"
                            min={0}
                            value={usuario.Usuario_Cedula}
                            onChange={this.onChangeCedula}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="txtUsuarioId">Login</label>
                        <input id="txtUsuarioId" className="form-control"
                            type="text"
                            name="Usuario_Id"
                            maxLength={30}
                            value={usuario.Usuario_Id}
                            disabled={!esNuevo}
                            onChange={this.onChangeInput}
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="ddlTipoUsuario">Tipo de Usuario</label>
                        <select id="ddlTipoUsuario" className="form-control" name="Rol_Id" onChange={this.onChangeInput} value={usuario.Rol_Id}>
                            <option value={1}>Administrador</option>
                            <option value={2}>Consulta</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="txtUsuarioPassword">Contraseña</label>
                        <input id="txtUsuarioPassword" className="form-control"
                            type="password"
                            name="Usuario_Password"
                            maxLength={50}
                            value={usuario.Usuario_Password || ''}
                            onChange={this.onChangeInput}
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="txtUsuarioPasswordConfirmacion">Confirmar Contraseña</label>
                        <input id="txtUsuarioPasswordConfirmacion" className="form-control"
                            type="password"
                            name="Usuario_Password_Confirmacion"
                            maxLength={50}
                            value={usuario.Usuario_Password_Confirmacion || ''}
                            onChange={this.onChangeInput}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="txtUsuarioEmail">Correo Electrónico</label>
                        <input id="txtUsuarioEmail" className="form-control"
                            type="email"
                            name="Usuario_Email"
                            maxLength={100}
                            value={usuario.Usuario_Email}
                            onChange={this.onChangeInput}
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group">
                        <label>Fecha de Vencimiento</label><span className="font-small">* 01/01/1900 indica que el acceso no vence.</span>
                        <DatePicker name="Usuario_Fec_Vencimiento" value={usuario.Usuario_Fec_Vencimiento} onChange={this.onChangeDate} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col mt-4">
                    <div className="custom-control custom-checkbox custom-control-inline">
                        <input id="chkUsuarioActivo" className="custom-control-input"
                            type="checkbox"
                            name="Usuario_Activo"
                            checked={usuario.Usuario_Activo}
                            onChange={this.onChangeCheckBox}
                        />
                        <label className="custom-control-label" htmlFor="chkUsuarioActivo">Activo</label>
                    </div>
                </div>
            </div>
            <hr />
            {esNuevo ? null :
                <div className="row">
                    <div className="col">
                        <strong>Última modificación:</strong> <span> {`${usuario.Usuario_Id_Actualiza} ${moment(usuario.Usuario_Fec_Actualizacion).format('DD-MM-YYYY hh:mm A')}`}</span>
                    </div>
                </div>
            }
            <div className="row mb-3 mt-4">
                <div className="col text-right">
                    <button className="btn btn-secondary mr-1" onClick={() => this.props.onGoBack()}>Cancelar</button>
                    <button className="btn btn-success" disabled={btnDisabled} onClick={this.onClickGuardarUsuario} >
                        {
                            loading ?
                                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faSyncAlt} spin /> : null
                        } Guardar</button>
                </div>
            </div>
        </Fragment >;
    }
};

const mapStateToProps = state => {
    return {
        token: state.usuario.token,
        usuario_actual: state.usuario.usuario
    };
};

export default connect(mapStateToProps)(memo(withRouter(UsuarioEditorSeccion)));