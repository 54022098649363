import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import { SiteRutas } from '../../../request/PathConfig';
import { checkEmail, checkPhone, checkNumber } from '../../../components/Util/Util';
import { Notification } from '../../../components/Util/Notification/Notification';
import { Insertar_Empresa_Request, Actualizar_Empresa_Request } from '../../../request/EmpresaRequest';

class DetalleSeccion extends PureComponent {
    _isMounted = false;
    state = {
        esNuevo: true,
        empresa: {
            Empresa_Id: '',
            Empresa_Nombre: '',
            Empresa_Email: '',
            Empresa_Telefono: '',
            Empresa_Contacto_Id: '',
            Empresa_Contacto_Nombre: '',
            Empresa_Contacto_Apellido1: '',
            Empresa_Contacto_Apellido2: '',
            Empresa_Activa: true,
            Empresa_Fec_Actualizacion: new Date(),
            Usuario_Id_Actualiza: this.props.usuario_actual.Usuario_Id
        },
        btnDisabled: false,
        loading: false
    }

    componentDidMount() {
        this._isMounted = true;
        const { empresa, esNuevo } = this.props;
        if (empresa) {
            this._isMounted && this.setState({ empresa, esNuevo });
        }
        else {
            this._isMounted && this.setState({
                empresa: {
                    Empresa_Id: '',
                    Empresa_Nombre: '',
                    Empresa_Email: '',
                    Empresa_Telefono: '',
                    Empresa_Contacto_Id: '',
                    Empresa_Contacto_Nombre: '',
                    Empresa_Contacto_Apellido1: '',
                    Empresa_Contacto_Apellido2: '',
                    Empresa_Activa: true,
                    Empresa_Fec_Actualizacion: new Date(),
                    Usuario_Id_Actualiza: this.props.usuario_actual.Usuario_Id
                }, esNuevo: true
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        if (this.props.empresa && (!prevProps.empresa || this.props.empresa.Empresa_Id !== prevProps.empresa.Empresa_Id)) {
            this._isMounted && this.setState({ empresa: this.props.empresa, esNuevo: this.props.esNuevo });
        }
    }

    async guardarEmpresa() {
        const empresa = { ...this.state.empresa };
        if (this.validarDatosEmpresa(empresa)) {
            let response = null;
            if (this.state.esNuevo) {
                response = await Insertar_Empresa_Request(this.props.token, empresa);
            }
            else {
                response = await Actualizar_Empresa_Request(this.props.token, empresa);
            }

            if (response.Exitoso) {
                this._isMounted && this.state.esNuevo && this.setState({ esNuevo: false });
                Notification.success(response.Mensaje);
                this.props.onChangeEmpresa(empresa);
            }
            else {
                Notification.error(response.Mensaje);
                if (response.CodigoError === 2) {
                    let { history } = this.props;
                    history.push({
                        pathname: SiteRutas.Login
                    });
                }
            }
        }
        this._isMounted && this.setState({ btnDisabled: false, loading: false });
    }

    validarDatosEmpresa = pEmpresa => {

        if (!pEmpresa.Empresa_Id) {
            Notification.error("La Cédula de la empresa es requerida.");
            return false;
        }

        if (pEmpresa.Empresa_Id.length > 30) {
            Notification.error("La Cédula de la empresa debe contener como máximo 30 dígitos.");
            return false;
        }

        if (!pEmpresa.Empresa_Nombre) {
            Notification.error("El Nombre de la empresa es requerido.");
            return false;
        }

        if (pEmpresa.Empresa_Nombre.length > 100) {
            Notification.error("El Nombre de la empresa debe contener como máximo 100 caracteres.");
            return false;
        }

        if (!pEmpresa.Empresa_Email || !checkEmail(pEmpresa.Empresa_Email)) {
            Notification.error("Debe ingresar un Correo Electrónico válido.");
            return false;
        }

        if (pEmpresa.Empresa_Email.length > 100) {
            Notification.error("El Correo Electrónico debe contener como máximo 100 caracteres.");
            return false;
        }

        if (!pEmpresa.Empresa_Telefono || !checkPhone(pEmpresa.Empresa_Telefono)) {
            Notification.error("Debe ingresar un número de Teléfono válido.");
            return false;
        }

        if (pEmpresa.Empresa_Telefono.length > 25) {
            Notification.error("El Correo Electrónico debe contener como máximo 25 caracteres.");
            return false;
        }

        if (!pEmpresa.Empresa_Contacto_Id) {
            Notification.error("La Cédula del contacto es requerida.");
            return false;
        }

        if (pEmpresa.Empresa_Contacto_Id.length > 30) {
            Notification.error("La Cédula del contacto debe contener como máximo 30 dígitos.");
            return false;
        }

        if (!pEmpresa.Empresa_Contacto_Nombre) {
            Notification.error("El Nombre del contacto es requerido.");
            return false;
        }

        if (pEmpresa.Empresa_Contacto_Nombre.length > 25) {
            Notification.error("El Nombre del contacto debe contener como máximo 25 caracteres.");
            return false;
        }

        if (!pEmpresa.Empresa_Contacto_Apellido1) {
            Notification.error("El Primer Apellido del contacto es requerido.");
            return false;
        }

        if (pEmpresa.Empresa_Contacto_Apellido1.length > 25) {
            Notification.error("El Primer Apellido del contacto debe contener como máximo 25 caracteres.");
            return false;
        }

        if (!pEmpresa.Empresa_Contacto_Apellido2) {
            Notification.error("El Segundo Apellido del contacto es requerido.");
            return false;
        }

        if (pEmpresa.Empresa_Contacto_Apellido2.length > 25) {
            Notification.error("El Segundo Apellido del contacto debe contener menos de 26 caracteres.");
            return false;
        }

        return true;
    }

    onClickGuardarEmpresa = () => {
        this.setState({ btnDisabled: true, loading: true }, () => {
            this.guardarEmpresa();
        });
    }

    onChangeInput = event => {
        let { name, value } = event.currentTarget;
        this.setState({ empresa: { ...this.state.empresa, [name]: value } });
    }

    onChangeCedula = event => {
        const { name, value } = event.currentTarget;
        if (!value || checkNumber(value, 1, 30))
            this.setState({ empresa: { ...this.state.empresa, [name]: value } });
    }

    onChangeCheckBox = event => {
        const { name, checked } = event.currentTarget;
        this.setState({ empresa: { ...this.state.empresa, [name]: checked } });
    }

    onClickGoBack = event => {
        let { history } = this.props;
        history.push({
            pathname: SiteRutas.Empresas
        });
    }

    render() {
        const { empresa, esNuevo, loading, btnDisabled } = this.state;


        return <Fragment>
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="txtEmpresaId">Cédula</label>
                        <input id="txtEmpresaId" className="form-control"
                            type="number"
                            name="Empresa_Id"
                            min={0}
                            value={empresa.Empresa_Id}
                            disabled={!esNuevo}
                            onChange={this.onChangeCedula}
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="txtEmpresaNombre">Nombre</label>
                        <input id="txtEmpresaNombre" className="form-control"
                            type="text"
                            name="Empresa_Nombre"
                            maxLength={100}
                            value={empresa.Empresa_Nombre}
                            onChange={this.onChangeInput}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="txtEmpresaEmail">Correo Electrónico</label>
                        <input id="txtEmpresaEmail" className="form-control"
                            type="email"
                            name="Empresa_Email"
                            maxLength={100}
                            value={empresa.Empresa_Email}
                            onChange={this.onChangeInput}
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="txtEmpresaTelefono">Teléfono</label>
                        <input id="txtEmpresaTelefono" className="form-control"
                            type="text"
                            name="Empresa_Telefono"
                            maxLength={25}
                            value={empresa.Empresa_Telefono}
                            onChange={this.onChangeInput}
                        />
                    </div>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-6">
                    <h6>Contacto</h6>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="txtEmpresaContactoId">Cédula</label>
                        <input id="txtEmpresaContactoId" className="form-control"
                            type="number"
                            name="Empresa_Contacto_Id"
                            min={0}
                            value={empresa.Empresa_Contacto_Id}
                            onChange={this.onChangeCedula}
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="txtEmpresaContactoNombre">Nombre</label>
                        <input id="txtEmpresaContactoNombre" className="form-control"
                            type="text"
                            name="Empresa_Contacto_Nombre"
                            maxLength={25}
                            value={empresa.Empresa_Contacto_Nombre}
                            onChange={this.onChangeInput}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="txtEmpresaContactoApellido1">Primer Apellido</label>
                        <input id="txtEmpresaContactoApellido1" className="form-control"
                            type="text"
                            name="Empresa_Contacto_Apellido1"
                            maxLength={25}
                            value={empresa.Empresa_Contacto_Apellido1}
                            onChange={this.onChangeInput}
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="txtEmpresaContactoApellido2">Segundo Apellido</label>
                        <input id="txtEmpresaContactoApellido2" className="form-control"
                            type="text"
                            name="Empresa_Contacto_Apellido2"
                            maxLength={25}
                            value={empresa.Empresa_Contacto_Apellido2}
                            onChange={this.onChangeInput}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col mt-4">
                    <div className="custom-control custom-checkbox custom-control-inline">
                        <input id="chkEmpresaActiva" className="custom-control-input"
                            type="checkbox"
                            name="Empresa_Activa"
                            checked={empresa.Empresa_Activa}
                            onChange={this.onChangeCheckBox}
                        />
                        <label className="custom-control-label" htmlFor="chkEmpresaActiva">Activo</label>
                    </div>
                </div>
            </div>
            <hr />
            {esNuevo ? null :
                <div className="row">
                    <div className="col">
                        <strong>Última modificación:</strong> <span> {`${empresa.Usuario_Id_Actualiza} ${moment(empresa.Empresa_Fec_Actualizacion).format('DD-MM-YYYY hh:mm A')}`}</span>
                    </div>
                </div>
            }
            <div className="row mb-3 mt-4">
                <div className="col text-right">
                    <button className="btn btn-secondary mr-1" onClick={this.onClickGoBack}>Cancelar</button>
                    <button className="btn btn-success" disabled={btnDisabled} onClick={this.onClickGuardarEmpresa} >
                        {
                            loading ?
                                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faSyncAlt} spin /> : null
                        } Guardar</button>
                </div>
            </div>
        </Fragment>
    }
};

const mapStateToProps = state => {
    return {
        token: state.usuario.token,
        usuario_actual: state.usuario.usuario
    };
};

export default connect(mapStateToProps)(withRouter(DetalleSeccion));
