import { LoginRutas, GetResponseBody } from './PathConfig';

export const Login_Request = async (pUsuario, pContrasena) => {
    const data = { Usuario: pUsuario, Contrasena: pContrasena };
    const result = await fetch(LoginRutas.Login, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return await GetResponseBody(result);
};