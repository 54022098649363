import * as actionTypes from './actionTypes';

export const establecerUsuario = infoUsuario => ({
    type: actionTypes.SET_USER_INFO,
    payload: infoUsuario
});

export const establecerUsuarioConectado = state => ({
    type: actionTypes.SET_USER_LOGGED_IN,
    payload: state
});