import React, { Component, memo } from 'react';
import { connect } from 'react-redux';

import './LoginPage.css';
import { establecerUsuario, establecerUsuarioConectado } from '../../store/usuario/actions';
import { SiteRutas } from '../../request/PathConfig';
import { Notification } from '../../components/Util/Notification/Notification';
import { Login_Request } from '../../request/LoginRequest';
import Footer from '../../components/Footer/Footer';

class LoginPage extends Component {
    _isMounted = false;
    state = {
        username: '',
        password: ''
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async authenticateUser() {
        const { username, password } = this.state;

        if (this.validateInputData(username, password)) {
            const response = await Login_Request(username, password);
            if (response.Exitoso) {
                this._isMounted && this.setState({ username: '', password: '' });
                Notification.success("", "Se ha iniciado sesión.");

                this.setUserData(response.Datos);


                this.props.history.push({
                    pathname: SiteRutas.Home,
                    state: { usuario: response.Datos }
                });
            }
            else {
                Notification.error(response.Mensaje);
            }
        }
    }

    validateInputData = () => {
        const { username, password } = this.state;

        if (!username || !username.trim()) {
            Notification.error("Por favor ingrese un usuario.");
            return false;
        }

        if (!password || !password.trim()) {
            Notification.error("Por favor ingrese la contraseña.");
            return false;
        }

        return true;
    }

    setUserData = (pUserData) => {
        this.props.establecerUsuarioConectado(true);
        const token = pUserData.Token;
        pUserData.Token = '';
        this.props.establecerUsuario({ usuario: pUserData, token });
    }

    onInputChanged = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    passwordOnKeyPressed = event => {
        if (event.which === 13 || event.keyCode === 13) {
            this.authenticateUser();
        }
    }

    onLoginHandler = event => {
        event.preventDefault();
        this.authenticateUser();
    }

    render() {
        const { username, password } = this.state;

        return (
            <div className="row no-gutters">
                <div className="col-md-4 hidden-sm-down">
                    <div className="background">
                        <h1>Repuestos Originales</h1>
                        <img src='./imagenes/purdy_motor.png' alt="Purdy Motor" />
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="login-bg">
                        <div className="row no-gutters">
                            <div className="col-12 text-center">
                                <div className="logos">
                                    <div className="imagen img-fluid">
                                        <img src='./imagenes/GrupoMarcas.png' alt="Marcas Repuestos" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 text-center">
                                <p className="subtitle">Iniciar Sesión</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 ">
                                <label htmlFor="txtUsername">Usuario</label>
                                <input id="txtUsername" name="username"
                                    type="text"
                                    className="login"
                                    value={username}
                                    onChange={this.onInputChanged} />
                                <label htmlFor="txtPassword">Contraseña</label>
                                <input id="txtPassword" name="password"
                                    type="password"
                                    className="login"
                                    value={password}
                                    onChange={this.onInputChanged}
                                    onKeyPress={this.passwordOnKeyPressed} />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 text-center">
                                <button className="btn btn-iniciar" id="btn-iniciar" onClick={this.onLoginHandler}> Iniciar Sesión</button>
                            </div>
                        </div>
                    </div>
                    <Footer className="footer-login" />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    establecerUsuario,
    establecerUsuarioConectado
};

export default connect(null, mapDispatchToProps)(memo(LoginPage));