import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import usuarioReducer from './usuario/reducer';
import companiaReducer from './compania/reducer';

export default function configureStore(history, initialState) {

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const usuarioPersistConfig = {
        key: 'usuario',
        storage: storageSession,
        blacklist: []
    };

    const companiaPersistConfig = {
        key: 'compania',
        storage: storageSession
    };

    const rootReducer = combineReducers({
        usuario: persistReducer(usuarioPersistConfig, usuarioReducer),
        compania: persistReducer(companiaPersistConfig, companiaReducer),
        router: connectRouter(history)
    });

    const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));
    let persistor = persistStore(store);
    return { store, persistor };
}
