import { UsuarioRutas, GetResponseBody, GetHeaderRequest } from './PathConfig';

export const Actualizar_Usuario_Request = async (pToken, pUsuario) => {
    const result = await fetch(UsuarioRutas.Actualizar_Usuario, {
        method: 'POST',
        headers: GetHeaderRequest(pToken),
        body: JSON.stringify(pUsuario)
    });
    return await GetResponseBody(result);
};

export const Eliminar_Usuario_Request = async (pToken, pUsuario_Id) => {
    const result = await fetch(`${UsuarioRutas.Eliminar_Usuario}?pUsuario_Id=${pUsuario_Id}`, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};

export const Insertar_Usuario_Request = async (pToken, pUsuario) => {
    const result = await fetch(UsuarioRutas.Insertar_Usuario, {
        method: 'POST',
        headers: GetHeaderRequest(pToken),
        body: JSON.stringify(pUsuario)
    });
    return await GetResponseBody(result);
};

export const Obtener_Usuarios_Por_Empresa_Request = async (pToken, pEmpresa_Id) => {
    const result = await fetch(`${UsuarioRutas.Obtener_Usuarios_Por_Empresa}?pEmpresa_Id=${pEmpresa_Id}`, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};

export const Obtener_Usuario_Por_Id_Request = async (pToken, pUsuario_Id) => {
    const result = await fetch(`${UsuarioRutas.Obtener_Usuario_Por_Id}?pUsuario_Id=${pUsuario_Id}`, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};