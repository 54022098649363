const baseUrlAPI = process.env.REACT_APP_BASE_URL_API;
const baseUrl = process.env.REACT_APP_BASE_URL;

//Controllers Web API
const companiaController = 'API/Compania';
const empresaController = 'API/Empresa';
const loginController = 'API/Login';
const repuestoController = 'API/Repuesto';
const usuarioController = 'API/Usuario';

export const SiteRutas = {
    Base: baseUrl ? `${baseUrl}/` : '/',
    CompaniaEditor: `${baseUrl}/CompaniaEditor`,
    Companias: `${baseUrl}/Companias`,
    EmpresaEditor: `${baseUrl}/EmpresaEditor`,
    Empresas: `${baseUrl}/Empresas`,
    Login: `${baseUrl}/Login`,
    Home: `${baseUrl}/Home`
};

//Routes
export const CompaniaRutas = {
    Actualizar_Compania: `${baseUrlAPI}${companiaController}/Actualizar_Compania`,
    Asociar_Compania_Empresa: `${baseUrlAPI}${companiaController}/Asociar_Compania_Empresa`,
    Cargar_Compania_Imagen: `${baseUrlAPI}${companiaController}/Cargar_Compania_Imagen`,
    Desasociar_Compania_Empresa: `${baseUrlAPI}${companiaController}/Desasociar_Compania_Empresa`,
    Eliminar_Compania: `${baseUrlAPI}${companiaController}/Eliminar_Compania`,
    Insertar_Compania: `${baseUrlAPI}${companiaController}/Insertar_Compania`,
    Obtener_Companias: `${baseUrlAPI}${companiaController}/Obtener_Companias`,
    Obtener_Companias_Por_Empresa: `${baseUrlAPI}${companiaController}/Obtener_Companias_Por_Empresa`,
    Obtener_Compania_Por_Id: `${baseUrlAPI}${companiaController}/Obtener_Compania_Por_Id`
};

export const EmpresaRutas = {
    Actualizar_Empresa: `${baseUrlAPI}${empresaController}/Actualizar_Empresa`,
    Eliminar_Empresa: `${baseUrlAPI}${empresaController}/Eliminar_Empresa`,
    Insertar_Empresa: `${baseUrlAPI}${empresaController}/Insertar_Empresa`,
    Obtener_Empresas: `${baseUrlAPI}${empresaController}/Obtener_Empresas`,
    Obtener_Empresa_Por_Id: `${baseUrlAPI}${empresaController}/Obtener_Empresa_Por_Id`
};

export const LoginRutas = {
    Login: `${baseUrlAPI}${loginController}/Login`
};

export const RepuestoRutas = {
    Obtener_Stock_Repuesto_Compania_Por_Id: `${baseUrlAPI}${repuestoController}/Obtener_Stock_Repuesto_Compania_Por_Id`
};

export const UsuarioRutas = {
    Actualizar_Usuario: `${baseUrlAPI}${usuarioController}/Actualizar_Usuario`,
    Eliminar_Usuario: `${baseUrlAPI}${usuarioController}/Eliminar_Usuario`,
    Insertar_Usuario: `${baseUrlAPI}${usuarioController}/Insertar_Usuario`,
    Obtener_Usuarios_Por_Empresa: `${baseUrlAPI}${usuarioController}/Obtener_Usuarios_Por_Empresa`,
    Obtener_Usuario_Por_Id: `${baseUrlAPI}${usuarioController}/Obtener_Usuario_Por_Id`
};

//Request Header
export const GetHeaderRequest = pToken => {
    return {
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${pToken}`
    }
};

export const GetHeaderRequestFormData = pToken => {
    return {
        'Authorization': `Bearer ${pToken}`
    }
};

//Response Body
export const GetResponseBody = pResponse => {
  
    const contentType = pResponse.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
        return pResponse.json();
    } else {
        switch (pResponse.status) {
            case 401:
                return { Exitoso: false, Mensaje: 'No tiene permisos para acceder a este método.', CodigoError: 2 };
            case 500:
                return { Exitoso: false, Mensaje: 'Ocurrió un error en el servidor.', CodigoError: 999 };
            default:
                return { Exitoso: false, Mensaje: 'Ocurrió un error realizando la acción.', CodigoError: 999 };
        }
    }
};