import * as actionTypes from './actionTypes';

const initialState = {
    compania: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_COMPANY_SELECTED:
            return {
                ...state,
                compania: action.payload
            };
        default:
            return state;
    }
}