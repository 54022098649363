import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import { SiteRutas } from '../../request/PathConfig';
import { Notification } from '../../components/Util/Notification/Notification';
import { Insertar_Compania_Request, Actualizar_Compania_Request } from '../../request/CompaniaRequest';

class CompaniaEditorPage extends PureComponent {
    _isMounted = false;
    _reader = new FileReader();
    state = {
        esNuevo: true,
        compania: {
            Id: '',
            Compania_Id: 0,
            Compania_Nombre: '',
            Compania_Interfaz_Emp_Id: '',
            Compania_Activa: true,
            Compania_Fec_Actualizacion: new Date(),
            Usuario_Id_Actualiza: this.props.usuario_actual.Usuario_Id
        },
        file: null,
        btnDisabled: false,
        loading: false
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.estaLogueado && this.props.usuario_actual && this.props.usuario_actual.Rol_Id === 1) {

            if (this.props.location.state && this.props.location.state.compania) {
                const compania = { ...this.props.location.state.compania };
                this.setState({ compania, esNuevo: false });
            }
        }
        else {
            let { history } = this.props;
            history.push({
                pathname: SiteRutas.Login
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async guardarCompania() {
        const compania = { ...this.state.compania };
        if (this.validarDatosCompania(compania)) {
            let response = null;
            if (this.state.esNuevo) {
                response = await Insertar_Compania_Request(this.props.token, compania, this.state.file);
            }
            else {
                response = await Actualizar_Compania_Request(this.props.token, compania, this.state.file);
            }

            if (response.Exitoso) {
                this._isMounted && this.state.esNuevo && this.setState({ esNuevo: false });
                Notification.success(response.Mensaje);
            }
            else {
                Notification.error(response.Mensaje);
                if (response.CodigoError === 2) {
                    let { history } = this.props;
                    history.push({
                        pathname: SiteRutas.Login
                    });
                }
            }
        }
        this._isMounted && this.setState({ btnDisabled: false, loading: false });
    }

    validarDatosCompania = pCompania => {

        if (!pCompania.Compania_Id || isNaN(pCompania.Compania_Id) || parseInt(pCompania.Compania_Id) <= 0 || parseInt(pCompania.Compania_Id) > 2147483647) {
            Notification.error("El ID de la compañía es requerido y debe ser un valor numérico entre 1 y 2,147,483,647.");
            return false;
        }

        if (!pCompania.Compania_Nombre) {
            Notification.error("El Nombre de la compañía es requerido.");
            return false;
        }

        if (pCompania.Compania_Nombre.length > 100) {
            Notification.error("El Nombre de la compañía debe contener como máximo 100 caracteres.");
            return false;
        }

        if (!pCompania.Compania_Interfaz_Emp_Id || isNaN(pCompania.Compania_Interfaz_Emp_Id) || parseInt(pCompania.Compania_Interfaz_Emp_Id) <= 0 || parseInt(pCompania.Compania_Interfaz_Emp_Id) > 32767) {
            Notification.error("El Código de Interfaz es requerido y debe ser un valor numérico entre 1 y 32,767.");
            return false;
        }

        if (this.state.esNuevo && !this.state.file) {
            Notification.error("Debe seleccionar una imagen de Logo para la compañía.");
            return false;
        }

        return true;
    }

    onClickGuardarCompania = () => {
        this.setState({ btnDisabled: true, loading: true }, () => {
            this.guardarCompania();
        });
    }

    onChangeInput = event => {
        const { name, value } = event.currentTarget;
        this.setState({ compania: { ...this.state.compania, [name]: value } });
    }

    onChangeNumber = event => {
        const { name, value } = event.currentTarget;
        if (!value || !isNaN(value))
            this.setState({ compania: { ...this.state.compania, [name]: value } });
    }

    onChangeFile = event => {
        const { files, name } = event.currentTarget;

        if (files && files.length > 0 && files[0]) {
            if (!files[0].name.toLowerCase().endsWith(".png")) {
                Notification.error("Debe seleccionar una imagen PNG.");
                return false;
            }

            this._reader.onload = (e) => {
                const img = new Image();
                const $this = this;
                img.onload = function () {
                    if (this.height > 70) {
                        Notification.error('La imagen debe tener una altura no mayor a 70px.');
                        return false;
                    }
                    $this.setState({ [name]: files[0] });
                };
                img.onerror = () => {
                    this.setState({ [name]: null });
                    Notification.error("Debe seleccionar una imagen valida.");
                    return false;
                };
                img.src = e.target.result;
            };
            this._reader.readAsDataURL(files[0]);
        }
        else {
            this.setState({ [name]: null });
        }
    }

    onChangeCheckBox = event => {
        const { name, checked } = event.currentTarget;
        this.setState({ compania: { ...this.state.compania, [name]: checked } });
    }

    onClickGoBack = event => {
        let { history } = this.props;
        history.push({
            pathname: SiteRutas.Companias
        });
    }

    render() {
        const { compania, esNuevo, loading, btnDisabled } = this.state;

        return (
            <Fragment>
                <div className="main-titles">
                    <div className="container">
                        <div className="row">
                            <div className="col pb-3">
                                <h2>
                                    {`${esNuevo ? ' Nueva' : ' Editar'} Compañía`}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-3">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="txtCompaniaId">ID</label>
                                <input id="txtCompaniaId" className="form-control"
                                    type="number"
                                    name="Compania_Id"
                                    min={1}
                                    max={2147483647}
                                    value={compania.Compania_Id}
                                    disabled={!esNuevo}
                                    onChange={this.onChangeNumber}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="txtCompaniaNombre">Nombre</label>
                                <input id="txtCompaniaNombre" className="form-control"
                                    type="text"
                                    name="Compania_Nombre"
                                    maxLength={100}
                                    value={compania.Compania_Nombre}
                                    onChange={this.onChangeInput}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="txtCompaniaInterfaz">Código de Interfaz</label>
                                <input id="txtCompaniaInterfaz" className="form-control"
                                    type="number"
                                    name="Compania_Interfaz_Emp_Id"
                                    min={1}
                                    max={32767}
                                    value={compania.Compania_Interfaz_Emp_Id}
                                    onChange={this.onChangeNumber}
                                />
                            </div>
                        </div>
                        <div className="col mt-4">
                            <div className="custom-control custom-checkbox custom-control-inline">
                                <input id="chkCompaniaActiva" className="custom-control-input"
                                    type="checkbox"
                                    name="Compania_Activa"
                                    checked={compania.Compania_Activa}
                                    onChange={this.onChangeCheckBox}
                                />
                                <label className="custom-control-label" htmlFor="chkCompaniaActiva">Activo</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Logo *PNG con altura máxima de 70px</label>
                            <div className="custom-file">
                                <input id="fileInput" type="file" className="custom-file-input" name="file" onChange={this.onChangeFile} accept="image/x-png" />
                                <label className="custom-file-label" htmlFor="fileInput">{this.state.file ? this.state.file.name : 'Seleccionar imagen...'}</label>
                            </div>
                        </div>
                    </div>
                    <hr />
                    {esNuevo ? null :
                        <div className="row">
                            <div className="col">
                                <strong>Última modificación:</strong> <span> {`${compania.Usuario_Id_Actualiza} ${moment(compania.Compania_Fec_Actualizacion).format('DD-MM-YYYY hh:mm A')}`}</span>
                            </div>
                        </div>
                    }
                    <div className="row mb-3 mt-4">
                        <div className="col text-right">
                            <button className="btn btn-secondary mr-1" onClick={this.onClickGoBack}>Cancelar</button>
                            <button className="btn btn-success" disabled={btnDisabled} onClick={this.onClickGuardarCompania} >
                                {
                                    loading ?
                                        <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faSyncAlt} spin /> : null
                                } Guardar</button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usuario.token,
        usuario_actual: state.usuario.usuario,
        estaLogueado: state.usuario.estaLogueado
    };
};

export default connect(mapStateToProps)(withRouter(CompaniaEditorPage));