import React, { PureComponent, memo, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Navbar, Nav, Form } from 'react-bootstrap';

import { SiteRutas } from '../../request/PathConfig';
import { establecerCompania } from '../../store/compania/actions';
import { establecerUsuarioConectado } from '../../store/usuario/actions';
import { Notification } from '../Util/Notification/Notification';
import { Obtener_Companias_Por_Empresa_Request } from '../../request/CompaniaRequest';

class Header extends PureComponent {
    _isMounted = false;
    state = {
        logo: null,
        companias: [],
        compania_selected: '',
        opcion_selected: SiteRutas.Home
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.estaLogueado && this.props.usuario) {
            if (this.props.location && this.props.location.pathname) {
                this.setState({ opcion_selected: this.props.location.pathname });
            }

            this.cargarCompanias(this.props.usuario.Empresa_Id);
        }
        else {
            let { history } = this.props;
            history.push({
                pathname: SiteRutas.Login
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async cargarCompanias(pEmpresa_Id) {
        if (pEmpresa_Id) {
            const response = await Obtener_Companias_Por_Empresa_Request(this.props.token, pEmpresa_Id);
            if (response.Exitoso) {
                let logo = null;
                let compania_selected = '';
                if (response.Datos && response.Datos.length > 0) {
                    logo = response.Datos[0].LogoBase64;
                    compania_selected = response.Datos[0].Id;
                    this.props.establecerCompania(response.Datos[0]);
                }
                this._isMounted && this.setState({ companias: response.Datos, logo, compania_selected });
            }
            else {
                Notification.error(response.Mensaje);
            }
        }
    }

    onlogoutRequest = () => {
        this.props.establecerUsuarioConectado(false);
        this.props.history.push(SiteRutas.Login);
    }

    onChangeCompania = event => {
        const { value } = event.currentTarget;

        const compania = this.state.companias.filter(compania => compania.Id === value);
        if (compania && compania.length > 0) {
            this.props.establecerCompania(compania[0]);
            this.setState({ compania_selected: value, logo: compania[0].LogoBase64 });
        }
    }

    onMenuClick = (pOpcion) => {
        this.setState({ opcion_selected: pOpcion });
    }

    render() {
        const { companias, logo, compania_selected, opcion_selected } = this.state;

        if (!this.props.usuario)
            return null;

        return (
            <Fragment>
                <div className="brand">
                    <div className="container">
                        <div className="row">
                            <div className="logo col">
                                {
                                    logo ? <img src={logo} alt="logo de compañía" /> : null
                                }
                            </div>

                            <div className="col-auto text-right">
                                <ul className="user navbar-nav pt-1 pb-3 empresa">
                                    <li className="nav-item dropdown">
                                        <label className="mr-1">Empresa:</label>
                                        <span>{this.props.usuario.Empresa_Nombre}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-auto text-right">
                                <ul className="user navbar-nav compania">
                                    <li className="nav-item dropdown">
                                        <label htmlFor="ddlCompanias">Compañía:</label>
                                        <select id="ddlCompanias" onChange={this.onChangeCompania} value={compania_selected}>
                                            {
                                                companias && companias.length > 0 ?
                                                    companias.map(compania => <option key={compania.Id} value={compania.Id}>{compania.Compania_Nombre}</option>)
                                                    : null
                                            }
                                        </select>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-header">
                    <Navbar className="container" expand="lg">
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <ul className="navbar-nav mr-auto">
                                    <li className={`nav-item ${opcion_selected === SiteRutas.Home ? 'active' : ''}`}>
                                        <Link className="nav-link" to={SiteRutas.Home} onClick={() => this.onMenuClick(SiteRutas.Home)}>
                                            Consulta de Repuestos
                                        </Link>
                                    </li>
                                    {
                                        this.props.usuario.Rol_Id === 1 ?
                                            <Fragment>
                                                <li className={`nav-item ${opcion_selected === SiteRutas.Companias || opcion_selected === SiteRutas.CompaniaEditor ? 'active' : ''}`}>
                                                    <Link className="nav-link" to={SiteRutas.Companias} onClick={() => this.onMenuClick(SiteRutas.Companias)}>
                                                        Administración de Compañías
                                                </Link>
                                                </li>
                                                <li className={`nav-item ${opcion_selected === SiteRutas.Empresas || opcion_selected === SiteRutas.EmpresasEditor ? 'active' : ''}`}>
                                                    <Link className="nav-link" to={SiteRutas.Empresas} onClick={() => this.onMenuClick(SiteRutas.Empresas)}>
                                                        Administración de Empresas
                                                </Link>
                                                </li>
                                            </Fragment>
                                            : null
                                    }
                                </ul>

                            </Nav>
                            <Form inline>
                                <span className="navbar-text">
                                    <div className="col-auto text-right">
                                        <DropdownButton variant="btn-link" title={`${this.props.usuario.Usuario_Nombre} ${this.props.usuario.Usuario_Apellido1}`}>
                                            <Dropdown.Item onClick={() => this.onlogoutRequest()}>Salir</Dropdown.Item>
                                        </DropdownButton>
                                    </div>
                                </span>
                            </Form>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usuario.token,
        usuario: state.usuario.usuario,
        estaLogueado: state.usuario.estaLogueado
    };
};

export default connect(mapStateToProps, { establecerCompania, establecerUsuarioConectado })(memo(withRouter(Header)));