import React, { PureComponent, Fragment, memo } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { SiteRutas } from '../../request/PathConfig';
import DetalleSeccion from './EditorSecciones/DetalleSeccion';
import UsuariosSeccion from './EditorSecciones/UsuariosSeccion';
import CompaniasSeccion from './EditorSecciones/CompaniasSeccion';
import UsuarioEditorSeccion from './EditorSecciones/UsuarioEditorSeccion';

class EmpresaEditorPage extends PureComponent {
    state = {
        opcionId: 1,
        esNuevo: true,
        empresa: null,
        usuario: null
    }

    componentDidMount() {
        if (this.props.estaLogueado && this.props.usuario && this.props.usuario.Rol_Id === 1) {

            if (this.props.location.state && this.props.location.state.empresa) {
                const empresa = { ...this.props.location.state.empresa };
                this.setState({ empresa, esNuevo: false });
            }
        }
        else {
            let { history } = this.props;
            history.push({
                pathname: SiteRutas.Login
            });
        }
    }

    onChangeEmpresa = pEmpresa => {
        this.setState({ empresa: { ...pEmpresa }, esNuevo: false }, () => {
            if (this.props.history) {
                this.props.history.replace({
                    ...this.props.location,
                    state: { ...this.props.location.state, empresa: pEmpresa }
                });
            }
        });
    }

    onEditUsuario = pUsuario => {
        this.setState({ usuario: pUsuario ? { ...pUsuario } : null, opcionId: 4 });
    }

    onClickOpcion = pOpcionId => {
        if (this.state.opcionId !== pOpcionId && this.state.empresa) {
            this.setState({ opcionId: pOpcionId });
        }
    }

    render() {
        const { esNuevo, opcionId, empresa, usuario } = this.state;

        let seccion = null;
        switch (opcionId) {
            case 1:
                seccion = <DetalleSeccion empresa={empresa} esNuevo={esNuevo} onChangeEmpresa={this.onChangeEmpresa} />;
                break;
            case 2:
                seccion = <UsuariosSeccion empresa_Id={empresa.Empresa_Id} onEditUsuario={this.onEditUsuario} />;
                break;
            case 3:
                seccion = <CompaniasSeccion empresa_Id={empresa.Empresa_Id} />;
                break;
            case 4:
                seccion = <UsuarioEditorSeccion empresa_Id={empresa.Empresa_Id} usuario={usuario} onGoBack={() => this.onClickOpcion(2)} />;
                break;
            default:
                break;
        }

        return (
            <Fragment>
                <div className="main-titles">
                    <div className="container">
                        <div className="row">
                            <div className="col pb-3">
                                <h2>
                                    {`${esNuevo ? ' Nueva' : ' Editar'} Empresa`}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <button className={`btn btn-link nav-link ${opcionId === 1 ? 'active' : ''}`} onClick={() => this.onClickOpcion(1)}>Detalle</button>
                        </li>
                        <li className="nav-item">
                            <button className={`btn btn-link nav-link ${opcionId === 2 || opcionId === 4 ? 'active' : ''}`} onClick={() => this.onClickOpcion(2)}>Usuarios</button>
                        </li>
                        <li className="nav-item">
                            <button className={`btn btn-link nav-link ${opcionId === 3 ? 'active' : ''}`} onClick={() => this.onClickOpcion(3)}>Compañías Asociadas</button>
                        </li>
                    </ul>
                    <div className="tab-content">
                        {seccion}
                    </div>
                </div>
            </Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usuario.token,
        usuario: state.usuario.usuario,
        estaLogueado: state.usuario.estaLogueado
    };
};

export default connect(mapStateToProps)(withRouter(memo(EmpresaEditorPage)));