import React from 'react';

export default props => {
    const labelId = props.id ? `lbl${props.id}` : '';

    const checkedAssign = props.onChange ? { checked: props.checked || false } : { defaultChecked: props.checked || false };

    const inputElement = <input id={props.id}
        type="checkbox"
        className={`custom-control-input ${props.className || ''}`}
        aria-labelledby={labelId}
        onChange={props.onChange}
        {...checkedAssign}
        {...props.props}
    />;

    return (
        props.labelText ?
            <div className="custom-control custom-checkbox mt-2">
                {inputElement}
                <label id={labelId} className="custom-control-label" htmlFor={props.id}>{props.labelText}</label>
            </div>
            : inputElement
    );
};