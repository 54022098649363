import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faTrashAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { SiteRutas } from '../../request/PathConfig';
import Mensaje from '../../components/Util/Notification/Mensaje';
import { Notification } from '../../components/Util/Notification/Notification';
import { Obtener_Companias_Request, Eliminar_Compania_Request } from '../../request/CompaniaRequest';

const companiaImagen = require('../../assets/images/compania.png');

class CompaniaPage extends PureComponent {
    _isMounted = false;
    state = {
        selectedCompania: null,
        registros: [],
        loading: false
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.estaLogueado && this.props.usuario && this.props.usuario.Rol_Id === 1) {
            this.cargarRegistros();
        }
        else {
            let { history } = this.props;
            history.push({
                pathname: SiteRutas.Login
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async cargarRegistros() {
        this.setState({ loading: true });

        const response = await Obtener_Companias_Request(this.props.token);
        if (response.Exitoso) {
            this._isMounted && this.setState({ loading: false, registros: response.Datos || [] });
        }
        else {
            this._isMounted && this.setState({ loading: false });
            Notification.error(response.Mensaje);
            if (response.CodigoError === 2) {
                let { history } = this.props;
                history.push({
                    pathname: SiteRutas.Login
                });
            }
        }
    }

    async eliminarRegistro(pRegistro) {
        const resultado = await Mensaje.confirmacion('Confirmación', `¿Está seguro que desea eliminar la compañía con código ${pRegistro.Compania_Id}?`, 'Eliminar');
        if (resultado) {
            const response = await Eliminar_Compania_Request(this.props.token, pRegistro.Id);
            if (response.Exitoso) {
                const indiceRegistro = this.state.registros.findIndex(item => item.Id === pRegistro.Id);
                if (indiceRegistro > -1) {
                    let registros = [...this.state.registros];
                    registros.splice(indiceRegistro, 1);
                    this._isMounted && this.setState({ registros });
                }

                Notification.success(response.Mensaje);
            }
            else {
                if (response.Mensaje.includes("FK_")) {
                    Notification.error("Ocurrió un error al intentar eliminar la compañía, por favor verifique sus dependencias.");
                }
                else {
                    Notification.error(response.Mensaje);
                    if (response.CodigoError === 2) {
                        let { history } = this.props;
                        history.push({
                            pathname: SiteRutas.Login
                        });
                    }
                }
            }
        }
    }

    onClickEliminar = event => {
        const { selectedCompania } = this.state;
        if (selectedCompania) {
            this.eliminarRegistro(selectedCompania);
        }
        else {
            Notification.warning('Por favor, seleccione la compañía que desea eliminar.');
        }
    }

    onClickNewEdit = pCompania => {
        let path = {
            pathname: SiteRutas.CompaniaEditor,
            state: {}
        };
        if (pCompania) {
            path.state.compania = pCompania;
        }

        this.props.history.push(path);
    }

    onClickEditar = () => {
        const { selectedCompania } = this.state;
        if (selectedCompania) {
            this.onClickNewEdit(selectedCompania);
        }
        else {
            Notification.warning('Por favor, seleccione la compañía que desea modificar.');
        }
    }

    onSelectedRowCompania = (row, isSelect, rowIndex, e) => {
        this.setState({ selectedCompania: isSelect ? row : null });
    }

    render() {
        const paginacionOpciones = {
            sizePerPage: 7,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true
        };

        const { SearchBar } = Search;

        const columns = [{
            dataField: 'Compania_Logo',
            text: '',
            formatter: (cellContent, row) => (
                <div>
                    <img src={row.LogoBase64} alt={row.Compania_Nombre} style={{ width: '100px', textAlign: 'center' }}></img>
                </div>
            )
        }, {
            dataField: 'Compania_Id',
            text: 'Id'
        }, {
            dataField: 'Compania_Nombre',
            text: 'Nombre'
        }, {
            dataField: 'Compania_Activa',
            text: 'Estado',
            formatter: (cellContent, row) => (
                row.Compania_Activa ? 'Activa' : 'Inactiva'
            )
        }];

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn: true,
            nonSelectable: [],
            bgColor: 'rgba(0,0,0,.075)',
            onSelect: this.onSelectedRowCompania
        };

        const rowEvents = {
            onDoubleClick: (e, row, rowIndex) => {
                this.onClickNewEdit(row);
            }
        };

        return (
            <ToolkitProvider
                keyField='Compania_Id'
                data={this.state.registros}
                columns={columns}
                search={{ searchFormatted: true }}>
                {
                    props => (
                        <Fragment>
                            <div className="main-titles">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <h2>Administración de Compañías</h2>
                                        </div>
                                        <div className="col text-right">
                                            <img className="img-fluid" src={companiaImagen} alt='imagen compañía' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="botones">
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <SearchBar placeholder='Buscar' {...props.searchProps} />
                                        </div>
                                        <div className="col text-right">
                                            <button className="btn btn-success mr-1" title="Nuevo" onClick={() => this.onClickNewEdit(null)}><FontAwesomeIcon icon={faPlus} /></button>
                                            <button className="btn btn-success mr-1" title="Editar" onClick={this.onClickEditar}><FontAwesomeIcon icon={faPen} /></button>
                                            <button className="btn btn-success" title="Eliminar" onClick={this.onClickEliminar}><FontAwesomeIcon icon={faTrashAlt} /></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mt-4">
                                        <div className="table-responsive">
                                            <BootstrapTable
                                                {...props.baseProps}
                                                selectRow={selectRow}
                                                rowEvents={rowEvents}
                                                pagination={paginationFactory(paginacionOpciones)}
                                                noDataIndication="No se encontraron registros."
                                                bootstrap4
                                                hover
                                            />
                                        </div>
                                        {
                                            this.state.loading ?
                                                <React.Fragment>
                                                    Cargando Información... <FontAwesomeIcon icon={faSpinner} spin />
                                                </React.Fragment>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )
                }
            </ToolkitProvider >
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usuario.token,
        usuario: state.usuario.usuario,
        estaLogueado: state.usuario.estaLogueado
    };
};

export default connect(mapStateToProps)(withRouter(CompaniaPage));