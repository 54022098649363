import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faTrashAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { SiteRutas } from '../../../request/PathConfig';
import Mensaje from '../../../components/Util/Notification/Mensaje';
import { Notification } from '../../../components/Util/Notification/Notification';
import { Obtener_Usuarios_Por_Empresa_Request, Eliminar_Usuario_Request } from '../../../request/UsuarioRequest';

class UsuariosSeccion extends PureComponent {
    _isMounted = false;
    state = {
        selectedUsuario: null,
        registros: [],
        loading: false
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.estaLogueado && this.props.usuario && this.props.usuario.Rol_Id === 1) {
            this.cargarRegistros();
        }
        else {
            let { history } = this.props;
            history.push({
                pathname: SiteRutas.Login
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async cargarRegistros() {
        this.setState({ loading: true });

        const response = await Obtener_Usuarios_Por_Empresa_Request(this.props.token, this.props.empresa_Id);
        if (response.Exitoso) {
            this._isMounted && this.setState({ loading: false, registros: response.Datos || [] });
        }
        else {
            this._isMounted && this.setState({ loading: false });
            Notification.error(response.Mensaje);
            if (response.CodigoError === 2) {
                let { history } = this.props;
                history.push({
                    pathname: SiteRutas.Login
                });
            }
        }
    }

    async eliminarRegistro(pRegistro) {
        const resultado = await Mensaje.confirmacion('Confirmación', `¿Está seguro que desea eliminar el usuario con código ${pRegistro.Usuario_Id}?`, 'Eliminar');
        if (resultado) {
            const response = await Eliminar_Usuario_Request(this.props.token, pRegistro.Usuario_Id);
            if (response.Exitoso) {
                const indiceRegistro = this.state.registros.findIndex(item => item.Usuario_Id === pRegistro.Usuario_Id);
                if (indiceRegistro > -1) {
                    let registros = [...this.state.registros];
                    registros.splice(indiceRegistro, 1);
                    this._isMounted && this.setState({ registros });
                }

                Notification.success(response.Mensaje);
            }
            else {
                if (response.Mensaje.includes("FK_")) {
                    Notification.error("Ocurrió un error al intentar eliminar el usuario, por favor verifique sus dependencias.");
                }
                else {
                    Notification.error(response.Mensaje);
                    if (response.CodigoError === 2) {
                        let { history } = this.props;
                        history.push({
                            pathname: SiteRutas.Login
                        });
                    }
                }
            }
        }
    }

    onClickEliminar = event => {
        const { selectedUsuario } = this.state;
        if (selectedUsuario) {
            this.eliminarRegistro(selectedUsuario);
        }
        else {
            Notification.warning('Por favor, seleccione el usuario que desea eliminar.');
        }
    }

    onClickNewEdit = pUsuario => {
        this.props.onEditUsuario(pUsuario);
    }

    onClickEditar = () => {
        const { selectedUsuario } = this.state;
        if (selectedUsuario) {
            this.onClickNewEdit(selectedUsuario);
        }
        else {
            Notification.warning('Por favor, seleccione el usuario que desea modificar.');
        }
    }

    onSelectedRowUsuario = (row, isSelect, rowIndex, e) => {
        this.setState({ selectedUsuario: isSelect ? row : null });
    }

    render() {
        const paginacionOpciones = {
            sizePerPage: 7,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true
        };

        const { SearchBar } = Search;

        const columns = [{
            dataField: 'Usuario_Id',
            text: 'Login'
        }, {
            dataField: 'Usuario_Cedula',
            text: 'Cédula',

        }, {
            dataField: 'Usuario_Nombre',
            text: 'Nombre',
            formatter: (cellContent, row) => (`${row.Usuario_Nombre} ${row.Usuario_Apellido1} ${row.Usuario_Apellido2}`)
        }, {
            dataField: 'Usuario_Fec_Vencimiento',
            text: 'Fecha de Vencimiento',
            formatter: (cellContent, row) => (moment(row.Usuario_Fec_Vencimiento).format('DD-MM-YYYY'))
        }];

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn: true,
            nonSelectable: [],
            bgColor: 'rgba(0,0,0,.075)',
            onSelect: this.onSelectedRowUsuario
        };

        const rowEvents = {
            onDoubleClick: (e, row, rowIndex) => {
                this.onClickNewEdit(row);
            }
        };

        return (
            <ToolkitProvider
                keyField='Usuario_Id'
                data={this.state.registros}
                columns={columns}
                search={{ searchFormatted: true }}>
                {
                    props => (
                        <Fragment>
                            <div className="botones">
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <SearchBar placeholder='Buscar' {...props.searchProps} />
                                    </div>
                                    <div className="col text-right">
                                        <button className="btn btn-success mr-1" title="Nuevo" onClick={() => this.onClickNewEdit(null)}><FontAwesomeIcon icon={faPlus} /></button>
                                        <button className="btn btn-success mr-1" title="Editar" onClick={this.onClickEditar}><FontAwesomeIcon icon={faPen} /></button>
                                        <button className="btn btn-success" title="Eliminar" onClick={this.onClickEliminar}><FontAwesomeIcon icon={faTrashAlt} /></button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col mt-4">
                                    <div className="table-responsive">
                                        <BootstrapTable
                                            {...props.baseProps}
                                            selectRow={selectRow}
                                            rowEvents={rowEvents}
                                            pagination={paginationFactory(paginacionOpciones)}
                                            noDataIndication="No se encontraron registros."
                                            bootstrap4
                                            hover
                                        />
                                    </div>
                                    {
                                        this.state.loading ?
                                            <React.Fragment>
                                                Cargando Información... <FontAwesomeIcon icon={faSpinner} spin />
                                            </React.Fragment>
                                            : null
                                    }
                                </div>
                            </div>
                        </Fragment>
                    )
                }
            </ToolkitProvider >
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usuario.token,
        usuario: state.usuario.usuario,
        estaLogueado: state.usuario.estaLogueado
    };
};

export default connect(mapStateToProps)(withRouter(UsuariosSeccion));