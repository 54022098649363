import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import { toCurrency } from '../../components/Util/Util';
import { SiteRutas } from '../../request/PathConfig';
import { Notification } from '../../components/Util/Notification/Notification';
import { Consultar_Repuesto_Por_Id_Request } from '../../request/RepuestoRequest';

const repuestoImagen = require('../../assets/images/repuesto.png');

class RepuestoPage extends PureComponent {
    _isMounted = false;
    state = {
        repuesto_Id: '',
        repuestos: [],
        consulta: false,
        btnDisabled: false,
        loading: false
    }

    componentDidMount() {
        this._isMounted = true;

        if (!this.props.estaLogueado || !this.props.usuario) {
            let { history } = this.props;
            history.push({
                pathname: SiteRutas.Login
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        if (this.props.compania && prevProps.compania && this.props.compania.Id !== prevProps.compania.Id) {
            this.setState({
                repuesto_Id: '',
                repuestos: [],
                consulta: false,
                btnDisabled: false,
                loading: false
            });
        }
    }

    async consultarRepuesto(pRepuesto_Id) {
        if (pRepuesto_Id) {
            if (pRepuesto_Id.length <= 20) {
                const consulta = {
                    Compania_Id: this.props.compania.Compania_Id,
                    Empresa_Id: this.props.usuario.Empresa_Id,
                    Usuario_Id: this.props.usuario.Usuario_Id,
                    Interfaz: this.props.compania.Compania_Interfaz_Emp_Id,
                    Conexion: this.props.compania.Compania_Conexion,
                    Repuesto_Id: pRepuesto_Id
                };
                const response = await Consultar_Repuesto_Por_Id_Request(this.props.token, consulta);
                if (response.Exitoso) {
                    this._isMounted && this.setState({ repuestos: response.Datos, consulta: true });
                }
                else {
                    this._isMounted && this.setState({ repuestos: [], consulta: true });
                    Notification.error(response.Mensaje);
                    if (response.CodigoError === 2) {
                        let { history } = this.props;
                        history.push({
                            pathname: SiteRutas.Login
                        });
                    }                    
                }
            }
            else {
                Notification.error("El código de repuesto no puede exceder los 20 caracteres.");
            }
        }
        else {
            Notification.error("Debe ingresar un código de repuesto para poder consultar.");
        }
        this._isMounted && this.setState({ btnDisabled: false, loading: false });
    }

    onChangeInput = event => {
        const { name, value } = event.currentTarget;

        this.setState({ [name]: value });
    }

    onClickBuscar = pRepuesto_Id => {
        this.setState({ repuesto_Id: pRepuesto_Id, btnDisabled: true, loading: true }, () => {
            this.consultarRepuesto(pRepuesto_Id);
        });
    }

    onKeyPressRepuesto = event => {
        if (event.which === 13 || event.keyCode === 13) {
            if (this.state.repuesto_Id) {
                this.setState({ btnDisabled: true, loading: true }, () => {
                    this.consultarRepuesto(this.state.repuesto_Id);
                });
            }
        }
    }

    render() {
        const { repuesto_Id, repuestos, consulta, loading, btnDisabled } = this.state;

        let rowStock = null;
        let repuesto = null;
        let stock = 0;
        let precioFormat = '';
        if (repuestos && repuestos.length > 0) {
            stock = repuestos.reduce((a, b) => a + (b["Stock"] || 0), 0);
            repuesto = repuestos[0];

            rowStock = stock > 0 ? repuestos.map(repuesto => <tr key={repuesto.Suc_Id}>
                <td>{repuesto.Suc_Nombre}</td>
                <td>{repuesto.Stock}</td>
            </tr>) : null;

            precioFormat = `₡ ${toCurrency(repuesto.Precio)} ${repuesto.Exento === 'no' ? ' + IVA' : ''}`;
        }

        return (
            <div>
                <div className="main-titles">
                    <div className="container">
                        <div className="row">
                            <div className="col pt-4">
                                <h2>Consulta de Repuestos</h2>
                            </div>
                            <div className="col text-right">
                                <img className="img-fluid" src={repuestoImagen} alt='imagen repuestos' />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="container busqueda">
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="txtRepuestoId">Código de Repuesto</label>
                                <input id="txtRepuestoId"
                                    name="repuesto_Id"
                                    className="form-control"
                                    type="text"
                                    maxLength={20}
                                    value={repuesto_Id}
                                    onKeyPress={this.onKeyPressRepuesto}
                                    onChange={this.onChangeInput}
                                />
                            </div>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-success" onClick={() => this.onClickBuscar(this.state.repuesto_Id)} disabled={btnDisabled}>
                                {
                                    loading ?
                                        <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faSyncAlt} spin /> : null
                                } Buscar</button>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col">
                            {
                                consulta && !repuesto ?
                                    <p className="sin-exist">{`No se encontró un repuesto con el código ${repuesto_Id}.`}</p>
                                    : repuesto ?
                                        <div class="table-responsive">
                                            <table className="table borderless">
                                                <tbody>
                                                    <tr>
                                                        <td>ID</td>
                                                        <td>{repuesto.Art_Id}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Nombre</td>
                                                        <td>{repuesto.Nombre}</td>
                                                    </tr>
                                                    {
                                                        stock > 0 ?
                                                            <tr>
                                                                <td>Precio</td>
                                                                <td>{precioFormat}</td>
                                                            </tr>
                                                            : null
                                                    }
                                                    <tr>
                                                        <td>Reemplazo</td>
                                                        <td>
                                                            {
                                                                repuesto.Reemplazo === 'No Tiene' ? repuesto.Reemplazo :
                                                                    <button className="btn btn-link btn-reemplazos" onClick={() => this.onClickBuscar(repuesto.Reemplazo)} disabled={btnDisabled}>
                                                                        {repuesto.Reemplazo}
                                                                    </button>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Reemplazado</td>
                                                        <td>
                                                            {
                                                                repuesto.Reemplazado === 'No Tiene' ? repuesto.Reemplazado :
                                                                    <button className="btn btn-link btn-reemplazos" onClick={() => this.onClickBuscar(repuesto.Reemplazado)} disabled={btnDisabled}>
                                                                        {repuesto.Reemplazado}
                                                                    </button>
                                                            }
                                                        </td>
                                                    </tr>
                                                    {
                                                        stock > 0 ?
                                                            <tr>
                                                                <td>Total de Piezas</td>
                                                                <td>{stock}</td>
                                                            </tr>
                                                            : null
                                                    }
                                                    <tr></tr>
                                                    <tr>
                                                        <td>Fecha de Consulta</td>
                                                        <td>{moment(repuesto.Fecha_Consulta).format('DD-MM-YYYY hh:mm A')}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        : null
                            }
                        </div>
                        <div className="col border-left">
                            {
                                consulta && repuesto && stock === 0 ?
                                    <p className="sin-exist">No se encontraron existencias para este repuesto</p>
                                    : stock > 0 ?
                                        <div class="table-responsive">
                                            <table className="table existencias">
                                                <thead>
                                                    <tr>
                                                        <th>Ubicación</th>
                                                        <th>Stock</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {rowStock}
                                                </tbody>
                                            </table>
                                        </div>
                                        : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.usuario.token,
        usuario: state.usuario.usuario,
        compania: state.compania.compania,
        estaLogueado: state.usuario.estaLogueado
    };
};

export default connect(mapStateToProps)(withRouter(RepuestoPage));