import * as actionTypes from './actionTypes';

const initialState = {
    token: '',
    usuario: null,
    estaLogueado: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_USER_INFO:
            return {
                ...state,
                ...action.payload
            };
        case actionTypes.SET_USER_LOGGED_IN:
            let token = state.token;
            let usuario = state.usuario;
            if (!action.payload) {
                token = '';
                usuario = null;
            }
            return {
                ...state,
                estaLogueado: action.payload,
                token: token,
                usuario: usuario
            };
        default:
            return state;
    }
}